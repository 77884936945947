import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import './style.css'
import About from './views/about'
import Home from './views/home'
import Testimonials from './views/testimonials'
import Products from './views/products'
import Applications from './views/applications'
import SingleCategory from './views/singleCategory'
import Contact from './views/contact'

const App = () => {
  return (
    <Router>
      <div>
        <Route component={About} exact path="/about" />
        <Route component={Home} exact path="/" />
        <Route component={Testimonials} exact path="/testimonials" />
        <Route component={Products} exact path="/products" />
        <Route component={SingleCategory} exact path="/products/:category" />
        <Route component={Applications} exact path="/applications" />
        <Route component={Contact} path="/contact" />
      </div>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
