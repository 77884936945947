import React, { useEffect } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import './contact.css'

function Contact() {

    useEffect(()=>{
        window.scrollTo(0, 0)
    }, [])

    async function submitForm(){
        console.log("submitting form")
        console.log(document.getElementById("name").value)
        console.log(document.getElementById("email").value)
        console.log(document.getElementById("message").value)
        console.log(document.getElementById("company").value)
        const response = await fetch("https://dura-materials-default-rtdb.asia-southeast1.firebasedatabase.app/sontuosita-leads.json", {
          method: "POST",
          body: JSON.stringify({
            name: document.getElementById("name").value,
            company: document.getElementById("company").value,
            email: document.getElementById("email").value,
            message: document.getElementById("message").value,
          })
        })
        const data = await response.json()
        console.log(data)
        if ("name" in data){
            document.getElementById("name").value = ""
            document.getElementById("company").value = ""
            document.getElementById("email").value = ""
            document.getElementById("message").value = ""
            alert("Thank you for contacting us. We will get back to you shortly.")
        } else {
            alert("There was an error submitting your form. Please try again.")
        }
      }

  return (
    <div className='contact-container'>
        <Header />
        <div className='contact-details' id='contact-details'>
            <div className='contact-details-container'>
                <div className='contact-details-left'>
                    <div className='contact-details-left-container'>
                        <div className='contact-details-left-container-title'>
                            <h1>CONTACT US</h1>

                            <p className='contact-subline'>
                                We are always happy to hear from you. Please
                                contact us for any queries or feedback.
                            </p>

                            <div className='contact-details-left-container-title-address'>
                                <h3>Address</h3>
                                <p>
                                    102 Road No. 4, Defence Layout, Vidyaranyapura, Bengaluru, Karnataka
                                    560078
                                </p>

                                <h3>Phone</h3>
                                <p>+91 8495042742</p>

                                <h3>Email</h3>
                                <p>

                                    <a href='mailto:hello@sontuosita.com'>
                                        Mail Us At: hello@sontuosita.com
                                    </a>
                                </p>
                            </div>
                        </div>
                        <div style={{
                            marginTop: '50px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <h1>Download our Brochures</h1>
                        </div>
                    </div>
                </div>
                <div className='contact-details-right'>
                    <div className='contact-details-right-container'>
                        <div className='contact-details-right-container-title'>
                            <h1>Get in Touch</h1>
                            <p>
                                We are always happy to hear from you. Please
                                contact us for any queries or feedback.
                            </p>
                            
                            <div action="https://divspree.io/f/xnqoqzjy" method="POST">
                                <div className='contact-details-right-container-title-input'>
                                    <input
                                        type='text'
                                        id='name'
                                        name='name'
                                        placeholder='Name'
                                        required
                                    />
                                    <input
                                        type='email'
                                        id='email'
                                        name='email'
                                        placeholder='Email'
                                        required
                                    />
                                    <input
                                        type='company'
                                        id='company'
                                        name='company'
                                        placeholder='Your Copmany Name'
                                        required
                                    />
                                    <textarea
                                        name='message' 
                                        id='message'
                                        placeholder='Message'
                                        required
                                    ></textarea>

                                    <button type='submit' onClick={submitForm}>Send</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
  )
}

export default Contact