import React from 'react'
import { Link } from 'react-router-dom'

function Header() {
  return (
    <div className="home-navbar">
        <header data-role="Header" className="home-header max-width-container">
          <div className="home-navbar1">
            <div className="home-container01">
              <Link to="/">
              <img
                alt="search3271286"
                src="/playground_assets/home.svg"
                className="home-image"
                style={{
                  marginLeft: '20px',
                  marginRight: '10px'
                }}
              />
              </Link>
            </div>
            <div className="home-middle" style={{marginLeft: 10, marginRight: 10}}>
              <div className="home-left">
              {/* <Link to="/" className='home-text navbar-link'>
              <img
                alt="search3271286"
                src="/playground_assets/home.svg"
                className="home-image"
                style={{
                  marginLeft: '20px',
                  marginRight: '-20px'
                }}
              />
                HOME
              </Link> */}
                <a href="/#products" className="home-text navbar-link">
                  PRODUCTS
                </a>
                
                <Link to="/applications" className="home-text01 navbar-link">
                   OUR PROJECTS
                </Link>
                <a href="/#trending" className="home-text02 navbar-link">
                  TRENDING PRODUCTS
                </a>
              </div>
              <Link to="/" className="home-logo-center navbar-logo-title">
                <span>SONTUOSITÀ</span>
                <br></br>
              </Link>
              <div className="home-right">
                
              <Link to="/testimonials" className="home-text02 navbar-link">
                  TESTIMONIALS
                </Link>
                <Link to="/about" className="home-text05 navbar-link">
                  ABOUT US
                </Link>
                <Link to="/contact" className="home-text06 navbar-link">
                  <span className="navbar-link">CONTACT</span>
                </Link>
              </div>
            </div>
            <div className="home-icons">
              <a href="tel:+918495042742" className="home-link">
                <svg viewBox="0 0 1024 1024" className="home-icon">
                  <path d="M854 656q18 0 30 12t12 30v148q0 50-42 50-298 0-512-214t-214-512q0-42 50-42h148q18 0 30 12t12 30q0 78 24 150 8 26-10 44l-82 72q92 192 294 290l66-84q12-12 30-12 10 0 14 2 72 24 150 24z"></path>
                </svg>
              </a>
              <a
                href="mailto:hello@sontuosita.com?subject="
                className="home-link1"
              >
                <svg viewBox="0 0 1024 1024" className="home-icon02">
                  <path d="M854 342v-86l-342 214-342-214v86l342 212zM854 170q34 0 59 26t25 60v512q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h684z"></path>
                </svg>
              </a>
              <Link to="/about">
                <svg viewBox="0 0 877.7142857142857 1024" className="home-icon04">
                  <path d="M585.143 786.286v-91.429c0-10.286-8-18.286-18.286-18.286h-54.857v-292.571c0-10.286-8-18.286-18.286-18.286h-182.857c-10.286 0-18.286 8-18.286 18.286v91.429c0 10.286 8 18.286 18.286 18.286h54.857v182.857h-54.857c-10.286 0-18.286 8-18.286 18.286v91.429c0 10.286 8 18.286 18.286 18.286h256c10.286 0 18.286-8 18.286-18.286zM512 274.286v-91.429c0-10.286-8-18.286-18.286-18.286h-109.714c-10.286 0-18.286 8-18.286 18.286v91.429c0 10.286 8 18.286 18.286 18.286h109.714c10.286 0 18.286-8 18.286-18.286zM877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
                </svg>
              </Link>
            </div>
          </div>
          <a href="#mobile-menu">
            <div data-role="BurgerMenu" className="home-burger-menu">
              <svg viewBox="0 0 1024 1024" className="home-icon06">
                <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg>
            </div>
          </a>
          <div
            data-role="MobileMenu"
            id="mobile-menuu"
            className="home-mobile-menu"
          >
            <div className="home-nav" style={{paddingRight: 15}}>
              <div className="home-container02">
                <span className="home-logo-center1">SONTUOSITÀ</span>
                <div
                  data-role="CloseMobileMenu"
                  className="home-close-mobile-menu"
                >
                  <svg viewBox="0 0 1024 1024" className="home-icon08">
                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
              <div className="home-middle1">
                <Link to="/" className="home-text navbar-link">
                  HOME
                </Link>
              <Link to="/products" className="home-text navbar-link">
                  PRODUCTS
                </Link>
                <Link to="/applications" className="home-text01 navbar-link">
                  OUR PROJECTS
                </Link>
                <Link to="/testimonials" className="home-text02 navbar-link">
                  TESTIMONIALS
                </Link>
                <Link to="/about" className="home-text05 navbar-link">
                  ABOUT US
                </Link>
                <Link to="/contact" className="home-text06 navbar-link">
                  CONTACT
                </Link>
              </div>
            </div>
            <div>
            <a href="tel:+918495042742" className="home-link">
                <svg viewBox="0 0 1024 1024" className="home-icon" style={{marginLeft: -15}}>
                  <path d="M854 656q18 0 30 12t12 30v148q0 50-42 50-298 0-512-214t-214-512q0-42 50-42h148q18 0 30 12t12 30q0 78 24 150 8 26-10 44l-82 72q92 192 294 290l66-84q12-12 30-12 10 0 14 2 72 24 150 24z"></path>
                </svg>
              </a>
              <a
                href="mailto:hello@sontuosita.com?subject="
                className="home-link1"
              >
                <svg viewBox="0 0 1024 1024" className="home-icon02">
                  <path d="M854 342v-86l-342 214-342-214v86l342 212zM854 170q34 0 59 26t25 60v512q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h684z"></path>
                </svg>
              </a>
            </div>
          </div>
        </header>
      </div>
  )
}

export default Header