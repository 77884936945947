import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import GalleryCard2 from '../components/gallery-card2'
import './applications.css'
import Header from '../components/Header'
import Footer from '../components/Footer'

const Applications = (props) => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="applications-container">
      <Helmet>
        <title>Applications - Sontuosità Online Store</title>
        <meta
          property="og:title"
          content="Applications - Sontuosità Online Store"
        />
      </Helmet>
      {/* <div className="applications-navbar">
        <header
          data-role="Header"
          className="applications-header max-width-container"
        >
          <div className="applications-navbar1">
            <div className="applications-container01">
            <Link to="/">
              <img
                alt="search3271286"
                src="/playground_assets/home.svg"
                className="home-image"
                style={{
                  marginLeft: '20px',
                  marginRight: '-20px'
                }}
              />
              </Link>
            </div>
            <div className="applications-middle">
              <div className="applications-left">
                <Link to="/products" className="applications-text navbar-link">
                  PRODUCTS
                </Link>
                <Link
                  to="/applications"
                  className="applications-text01 navbar-link"
                >
                  APPLICATIONS
                </Link>
                <Link
                  to="/testimonials"
                  className="applications-text02 navbar-link"
                >
                  TESTIMONIALS
                </Link>
              </div>
              <Link
                to="/"
                className="applications-logo-center navbar-logo-title"
              >
                <span>SONTUOSITÀ</span>
                <br></br>
              </Link>
              <div className="applications-right">
                <Link to="/about" className="applications-text05 navbar-link">
                  ABOUT
                </Link>
                <a href="#contact-details" className="applications-text06 navbar-link">
                <span className="navbar-link">CONTACT</span>
                </a>
              </div>
            </div>
            <div className="applications-icons">
              <a href="tel:+918373958829" className="applications-link">
                <svg viewBox="0 0 1024 1024" className="applications-icon">
                  <path d="M854 656q18 0 30 12t12 30v148q0 50-42 50-298 0-512-214t-214-512q0-42 50-42h148q18 0 30 12t12 30q0 78 24 150 8 26-10 44l-82 72q92 192 294 290l66-84q12-12 30-12 10 0 14 2 72 24 150 24z"></path>
                </svg>
              </a>
              <a
                href="mailto:vishalvishwajeet422@gmail.com?subject="
                className="applications-link1"
              >
                <svg viewBox="0 0 1024 1024" className="applications-icon02">
                  <path d="M854 342v-86l-342 214-342-214v86l342 212zM854 170q34 0 59 26t25 60v512q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h684z"></path>
                </svg>
              </a>
              <svg
                viewBox="0 0 877.7142857142857 1024"
                className="applications-icon04"
              >
                <path d="M585.143 786.286v-91.429c0-10.286-8-18.286-18.286-18.286h-54.857v-292.571c0-10.286-8-18.286-18.286-18.286h-182.857c-10.286 0-18.286 8-18.286 18.286v91.429c0 10.286 8 18.286 18.286 18.286h54.857v182.857h-54.857c-10.286 0-18.286 8-18.286 18.286v91.429c0 10.286 8 18.286 18.286 18.286h256c10.286 0 18.286-8 18.286-18.286zM512 274.286v-91.429c0-10.286-8-18.286-18.286-18.286h-109.714c-10.286 0-18.286 8-18.286 18.286v91.429c0 10.286 8 18.286 18.286 18.286h109.714c10.286 0 18.286-8 18.286-18.286zM877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
              </svg>
            </div>
          </div>
          <div data-role="BurgerMenu" className="applications-burger-menu">
            <svg viewBox="0 0 1024 1024" className="applications-icon06">
              <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
            </svg>
          </div>
          <div data-role="MobileMenu" className="applications-mobile-menu">
            <div className="applications-nav">
              <div className="applications-container02">
                <span className="applications-logo-center1">SONTUOSITÀ</span>
                <div
                  data-role="CloseMobileMenu"
                  className="applications-close-mobile-menu"
                >
                  <svg viewBox="0 0 1024 1024" className="applications-icon08">
                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
              <div className="applications-middle1">
              <Link to="/products" className="home-text navbar-link">
                  PRODUCTS
                </Link>
                <Link to="/applications" className="home-text01 navbar-link">
                  APPLICATIONS
                </Link>
                <Link to="/testimonials" className="home-text02 navbar-link">
                  TESTIMONIALS
                </Link>
                <Link to="/about" className="home-text05 navbar-link">
                  ABOUT
                </Link>
                <a href="#contact-details" className="home-text06 navbar-link">
                  CONTACT
                </a>
              </div>
            </div>
            <div>
              <svg
                viewBox="0 0 950.8571428571428 1024"
                className="applications-icon10"
              >
                <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
              </svg>
              <svg
                viewBox="0 0 877.7142857142857 1024"
                className="applications-icon12"
              >
                <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
              </svg>
              <svg
                viewBox="0 0 602.2582857142856 1024"
                className="applications-icon14"
              >
                <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
              </svg>
            </div>
          </div>
        </header>
      </div> */}
      <Header />
      <div className="applications-container03">
        <h1 className="applications-text13">Living Room</h1>
        <div className="applications-gallery">
          <div className="applications-container04">
            <div className="applications-container05">
              <div className="applications-container06">
                <GalleryCard2
                  image_src="https://www.bhutrastones.com/wp-content/uploads/2018/10/Italian-Marble-3.jpg"
                  rootClassName="rootClassName2"
                  title="Living Room #1"
                ></GalleryCard2>
              </div>
              <div className="applications-container07">
                <GalleryCard2
                  image_src="https://www.bhutrastones.com/wp-content/uploads/2018/12/gudart_interior_-3__Bo_SNAyhMjT.jpg"
                  rootClassName="rootClassName3"
                  title="Living Room #2"
                  ></GalleryCard2>
              </div>
            </div>
            <div className="applications-container08">
              <GalleryCard2
                image_src="https://www.bhutrastones.com/wp-content/uploads/2018/10/dyna-marble-1.jpg"
                rootClassName="rootClassName"
                  title="Living Room #3"
                  ></GalleryCard2>
            </div>
          </div>
          <div className="applications-container09">
            <GalleryCard2
              image_src="https://5.imimg.com/data5/SELLER/Default/2022/6/MQ/AP/TB/153825164/living-room-interior-designing-service-1000x1000.jpg"
              rootClassName="rootClassName4"
                  title="Living Room #4"
                  ></GalleryCard2>
          </div>
        </div>
      </div>
      <div className="applications-container10">
        <h1 className="applications-text14">Bedroom</h1>
        <div className="applications-gallery1">
          <div className="applications-container11">
            <div className="applications-container12">
              <div className="applications-container13">
                <GalleryCard2
                  image_src="https://www.bhutrastones.com/wp-content/uploads/2018/10/White-Marble-1.jpg"
                  rootClassName="rootClassName7"
                  title="Bedroom #1"
                  ></GalleryCard2>
              </div>
              <div className="applications-container14">
                <GalleryCard2
                  image_src="https://www.bhutrastones.com/wp-content/uploads/2018/10/Onyx-Marble-6.jpg"
                  rootClassName="rootClassName8"
                  title="Bedroom #2"
                  ></GalleryCard2>
              </div>
            </div>
            <div className="applications-container15">
              <GalleryCard2
                image_src="https://www.bhutrastones.com/wp-content/uploads/2018/10/Vietnam-White-Marble-2.jpg"
                rootClassName="rootClassName6"
                  title="Bedroom #3"
                  ></GalleryCard2>
            </div>
          </div>
          <div className="applications-container16">
            <GalleryCard2
              image_src="https://www.bhutrastones.com/wp-content/uploads/2018/10/Wall-Cladding-Travertine-1.jpg"
              rootClassName="rootClassName1"
                  title="Bedroom #4"
                  ></GalleryCard2>
          </div>
        </div>
      </div>
      <div className="applications-container17">
        <h1 className="applications-text15">Kitchens</h1>
        <div className="applications-gallery2">
          <div className="applications-container18">
            <div className="applications-container19">
              <div className="applications-container20">
                <GalleryCard2
                  image_src="https://www.bhutrastones.com/wp-content/uploads/2018/10/Jupiter-Grey-Granite-1.jpg"
                  rootClassName="rootClassName11"
                  title="Kitchen #1"
                  ></GalleryCard2>
              </div>
              <div className="applications-container21">
                <GalleryCard2
                  image_src="https://www.bhutrastones.com/wp-content/uploads/2018/10/Black-Galaxy-Granite-1.jpg"
                  rootClassName="rootClassName12"
                  title="Kitchen #2"
                  ></GalleryCard2>
              </div>
            </div>
            <div className="applications-container22">
              <GalleryCard2
                image_src="https://www.bhutrastones.com/wp-content/uploads/2018/12/rathiarjun_-2__BmutiXDlYzl.jpg"
                rootClassName="rootClassName10"
                  title="Kitchen #3"
                  ></GalleryCard2>
            </div>
          </div>
          <div className="applications-container23">
            <GalleryCard2
              image_src="https://www.asenseinterior.com/assets/uploads/catalogue_category_hd_image_1041646051107.jpg"
              rootClassName="rootClassName9"
                  title="Kitchen #4"
                  ></GalleryCard2>
          </div>
        </div>
      </div>
      <div className="applications-container24">
        <h1 className="applications-text16">Bathrooms</h1>
        <div className="applications-gallery3">
          <div className="applications-container25">
            <div className="applications-container26">
              <div className="applications-container27">
                <GalleryCard2
                  image_src="https://www.bhutrastones.com/wp-content/uploads/2018/12/tileandfloor_-5__BpRaI2glrkH.jpg"
                  rootClassName="rootClassName15"
                  title="Bathroom #1"
                ></GalleryCard2>
              </div>
              <div className="applications-container28">
                <GalleryCard2
                  image_src="https://www.bhutrastones.com/wp-content/uploads/2018/10/Rainforest-Green-1.jpg"
                  rootClassName="rootClassName16"
                  title="Bathroom #2"
                  ></GalleryCard2>
              </div>
            </div>
            <div className="applications-container29">
              <GalleryCard2
                image_src="https://www.bhutrastones.com/wp-content/uploads/2018/10/Millenium-Marble.jpg"
                rootClassName="rootClassName14"
                  title="Bathroom #3"
                  ></GalleryCard2>
            </div>
          </div>
          <div className="applications-container30">
            <GalleryCard2
              image_src="/playground_assets/bathroom4.avif"
              rootClassName="rootClassName13"
                  title="Bathroom #4"
                  ></GalleryCard2>
          </div>
        </div>
      </div>
      <div className="applications-container31">
        <h1 className="applications-text17">Commercial</h1>
        <div className="applications-gallery4">
          <div className="applications-container32">
            <div className="applications-container33">
              <div className="applications-container34">
                <GalleryCard2
                  image_src="https://www.bhutrastones.com/wp-content/uploads/2018/10/Teakwood-Sandstone-1.jpg"
                  rootClassName="rootClassName19"
                  title="Commercial #1"
                  ></GalleryCard2>
              </div>
              <div className="applications-container35">
                <GalleryCard2
                  image_src="https://www.bhutrastones.com/wp-content/uploads/2018/10/Rainbow-Sandstone-1.jpg"
                  rootClassName="rootClassName20"
                  title="Commercial #2"
                  ></GalleryCard2>
              </div>
            </div>
            <div className="applications-container36">
              <GalleryCard2
                image_src="https://www.bhutrastones.com/wp-content/uploads/2018/10/Lift-Area-Rainforest-Brown-1.jpg"
                rootClassName="rootClassName18"
                  title="Commercial #3"
                  ></GalleryCard2>
            </div>
          </div>
          <div className="applications-container37">
            <GalleryCard2
              image_src="https://www.bhutrastones.com/wp-content/uploads/2018/10/Indian-Statuario-Marble-1.jpg"
              rootClassName="rootClassName17"
                  title="Commercial #4"
                  ></GalleryCard2>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Applications
