import React, { useEffect, useState } from 'react'
import GalleryCard1 from '../components/gallery-card1'
import { useParams } from 'react-router-dom'
import Header from '../components/Header'
import Footer from '../components/Footer'
import productsJson from '../products.json'
import './singleCategory.css'

function SingleCategory() {

    const products = JSON.parse(JSON.stringify(productsJson))
  const products2 = {
    "Granite": [
      '/playground_assets/granites/granites2/Amba-White.png',
      '/playground_assets/granites/granites2/Asian-Top.png',
      '/playground_assets/granites/granites2/Astoria.png',
      '/playground_assets/granites/granites2/Cats-Eye.png',
      '/playground_assets/granites/granites2/Colombo-Juparana.png',
      '/playground_assets/granites/granites2/Colonial-White.png',
      '/playground_assets/granites/granites2/Coral-White.png',
      '/playground_assets/granites/granites2/Ghiblee-Gold.png',
      '/playground_assets/granites/granites2/Golden-Beach.png',
      '/playground_assets/granites/granites2/Golden-Oak.png',
      '/playground_assets/granites/granites2/Imperial-Gold.png',
      '/playground_assets/granites/granites2/Ivory-Brown.png',
      '/playground_assets/granites/granites2/Ivory-Chiffon.png',
      '/playground_assets/granites/granites2/Kashmir-Cream.png',
      '/playground_assets/granites/granites2/New-Imperial-Gold.png',
      '/playground_assets/granites/granites2/New-Kashmir-White.png',
      '/playground_assets/granites/granites2/Paradiso-Bash.png',
      '/playground_assets/granites/granites2/Paradiso-Classic.png',
      '/playground_assets/granites/granites2/Pink-Juparana.png',
      '/playground_assets/granites/granites2/Red-Multi-Colour.png',
      '/playground_assets/granites/granites2/River-White.png',
      '/playground_assets/granites/granites2/Rosewood.png',
      '/playground_assets/granites/granites2/Shivakshi-Gold.png',
      '/playground_assets/granites/granites2/Shivakshi-Pink.png',
      '/playground_assets/granites/granites2/Strawberry-Pink.png',
      '/playground_assets/granites/granites2/Thunder-White.png',
      '/playground_assets/granites/granites2/Viscon-White.png',
      '/playground_assets/granites/granites1/Arizona-Gold.png',
      '/playground_assets/granites/granites1/Black-Galaxy-Brush-Hammered.png',
      '/playground_assets/granites/granites1/Black-Galaxy-Honed.png',
      '/playground_assets/granites/granites1/Black-Galaxy-Flamed.png',
      '/playground_assets/granites/granites1/Black-Galaxy-Lapatro.png',
      '/playground_assets/granites/granites1/Black-Galaxy.png',
      '/playground_assets/granites/granites1/Black-Pearl.png',
      '/playground_assets/granites/granites1/Flash-Blue.png',
      '/playground_assets/granites/granites1/G20.png',
      '/playground_assets/granites/granites1/Impala-Black.png',
      '/playground_assets/granites/granites1/Imperial-Brown.png',
      '/playground_assets/granites/granites1/Indian-Black.png',
      '/playground_assets/granites/granites1/Kingfisher-Blue.png',
      '/playground_assets/granites/granites1/Lavender-Blue.png',
      '/playground_assets/granites/granites1/Moon-White.png',
      '/playground_assets/granites/granites1/New-Tropic-Brown.png',
      '/playground_assets/granites/granites1/Prada-Gold.png',
      '/playground_assets/granites/granites1/SK-Blue.png',
      '/playground_assets/granites/granites1/Spice-Black.png',
      '/playground_assets/granites/granites1/Star-Galaxy.png',
      '/playground_assets/granites/granites1/Steel-Grey.png',
      '/playground_assets/granites/granites3/Absolute-Black.png',
      '/playground_assets/granites/granites3/Coffee-Brown.png',
      '/playground_assets/granites/granites3/Khammam-Black.png',
      '/playground_assets/granites/granites3/Pegasus-Brown.png',
      '/playground_assets/granites/granites3/Sapphire-Brown.png',
      '/playground_assets/granites/granites3/Tan-Brown.png',
      '/playground_assets/granites/granites4/Bala-Flower.png',
      '/playground_assets/granites/granites4/Black-Magic.png',
      '/playground_assets/granites/granites4/Chima-Pink.png',
      '/playground_assets/granites/granites4/Crystal-Yellow-1.png',
      '/playground_assets/granites/granites4/Crystal-Yellow-2.png',
      '/playground_assets/granites/granites4/Desert-Brown.png',
      '/playground_assets/granites/granites4/French-Green.png',
      '/playground_assets/granites/granites4/Indian-Green-Marble.png',
      '/playground_assets/granites/granites4/Jhansi-Red.png',
      '/playground_assets/granites/granites4/Koliwada.png',
      '/playground_assets/granites/granites4/New-Imperial-Red.png',
      '/playground_assets/granites/granites4/P-White.png',
      '/playground_assets/granites/granites4/Pink-Panther.png',
      '/playground_assets/granites/granites4/Rajasthan-Black.png',
      '/playground_assets/granites/granites4/Raniwada.png',
      '/playground_assets/granites/granites4/Rosy-Pink.png',
      '/playground_assets/granites/granites4/Royal-Cream.png',
      '/playground_assets/granites/granites4/S-White.png',
      '/playground_assets/granites/granites4/Tiger-Skin.png',
      '/playground_assets/granites/granites4/Tomato-Red.png',
      '/playground_assets/granites/granites4/Z-Brown.png',
    ]
  }

    const categoryDescriptions = {
        "Indian Marbles":
            "Indian marble is known for its unique beauty and durability, making it a popular choice for a variety of projects. Our team of skilled craftsmen carefully selects and processes the finest quality marble blocks to ensure that our clients receive only the best products. We also take great care in delivering the materials on time and in perfect condition, ensuring the satisfaction of our clients.  Our Indian marble collection includes a wide range of colors, textures, and patterns that can be customized to fit the unique needs and preferences of our clients.",
        "Imported Marbles":
            "If you are looking for marbles with exotic patterns and designs, imported marbles are the perfect choice. We offer an exclusive range of imported marbles sourced from the finest quarries across the world. Our imported marbles include Italian, Spanish, Turkish, and other international marbles. With their unique veining patterns and vibrant colors, these marbles are perfect for adding a touch of luxury to your project.",
        "Concrete Tiles":
            "Concrete tiles are a versatile and durable option for flooring, walls, and even outdoor spaces. At Sontuosità, we offer a wide range of concrete tiles in various colors, sizes, and patterns to suit the aesthetic and functional needs of our customers. Our concrete tiles are made with high-quality materials and modern technology to ensure that they are strong, long-lasting, and easy to maintain. They are also resistant to moisture, stains, and scratches, making them an excellent choice for high-traffic areas.",
        "Granite":
            "Granite is a highly sought-after material due to its durability, resistance to scratches and heat, and low maintenance requirements. Our granite products are sourced from the finest quarries around the world and are available in a variety of colors and finishes to suit any design aesthetic. Our granite products are perfect for a range of applications, including countertops, flooring, and decorative accents. We offer both polished and honed finishes to provide a range of design options, from sleek and modern to classic and timeless.",
        "Vitrified Tiles":
            "At Sontuosità, we offer a wide range of vitrified tiles that are perfect for adding a touch of elegance and style to your space. Our vitrified tiles are made using a unique process that involves the vitrification of clay at very high temperatures. This process results in tiles that are non-porous, highly durable, and easy to maintain. With a variety of colors, designs, and finishes to choose from, you're sure to find the perfect vitrified tiles to match your interior design needs.",
        "Bathroom & Sanitary Fittings":
            "Sontuosità also offers a range of bathroom and sanitary fittings to add functionality and style to your bathroom. Our products are made using high-quality materials and are designed to provide the perfect balance between style and functionality. We offer a variety of bathroom fittings, including taps, showers, washbasins, toilets, and more, all available in different sizes and styles to suit your specific needs."
    }

    const categoryImages = {
        "Indian Marbles": "/playground_assets/indian-1500w.jpeg",
        "Imported Marbles": "/playground_assets/imported-1500w.jpg",
        "Concrete Tiles": "https://thenuancestudio.com/assets/img/fuji.jpg",
        "Granite": "/playground_assets/granite2-1500w.webp",
        "Vitrified Tiles": "/playground_assets/vetrified.webp",
        "Bathroom & Sanitary Fittings": "/playground_assets/bathroom.png"
    }

    const brochures = {
      "Concrete Tiles": require("../brochures/Surface Panels Catalogue - NS.pdf"),
      "Indian Marbles": "/brochures/Indian-Marble.pdf",
      "Granite": "/brochures/Indian-Granite.pdf",
      "Imported Marbles": "/brochures/Imported-Marble-RKMarble.pdf",
      "Bathroom & Sanitary Fittings": "/brochures/wash-basin-catalogue.pdf"
    }

  const [doesntExist, setdoesntExist] = useState(false)
  
  const params = useParams()

  function checkIfCategoryExists() {
    if (Object.keys(products).every(product => product !== params.product)) {
      if (Object.keys(products2).every(product => product !== params.product)) {
        setdoesntExist(true)
      }
    }
  }

  const [product, setproduct] = useState(products[params.product] || products2[params.product])
  const [uriOfSelectedProduct, seturiOfSelectedProduct] = useState(null)

  const [showLeadGenForm, setshowLeadGenForm] = useState(false)
  const [leadFormFilledFilled, setleadFormFilledFilled] = useState(false)

  function leadForm(url) {
    setshowLeadGenForm(true)
    console.log("AAS")
    console.log(showLeadGenForm)
  }

  async function submitForm(){
    console.log("submitting form")
    console.log(document.getElementById("name").value)
    console.log(document.getElementById("email").value)
    console.log(document.getElementById("phone").value)
    console.log(document.getElementById("company").value)
    const response = await fetch("https://dura-materials-default-rtdb.asia-southeast1.firebasedatabase.app/sontuosita-leads.json", {
      method: "POST",
      body: JSON.stringify({
        name: document.getElementById("name").value,
        company: document.getElementById("company").value,
        email: document.getElementById("email").value,
        phone: document.getElementById("phone").value,
        product: product
      })
    })
    const data = await response.json()
    console.log(data)
    uriOfSelectedProduct && (window.location.href = uriOfSelectedProduct)
  }

  useEffect(() => {
    checkIfCategoryExists()
    console.log(doesntExist);
    window.scrollTo(0, 0)
  }, [])
    
  return (
    <div>
      {
        showLeadGenForm ? (
      <div style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        display: "flex",
        flexDirection: 'row',
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0,0,0,0.6)",
        zIndex: 10000
      }}>
        <div style={{
          backgroundColor: "white",
          backgroundImage: "url(/playground_assets/bright-squares.png)",
          padding: "1.6rem",
          paddingLeft: "2rem",
          paddingRight: "2rem",
          borderRadius: "0.3rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "0.8rem"
        }}
        className='dialog'
        >
          <div style={{
            display: "flex",
            width: '100%',
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: 'center'
          }}>
            {/* <img src="/playground_assets/dura.png" alt="" style={{
              height: "3rem",
            }} /> */}
            <span style={{
              fontWeight: 500,
              fontSize: '1.8rem',

            }}>SONTUOSITÀ</span>
          <h2 style={{color: "black", marginTop: '0.5rem'}}>May we get to know you?</h2>
          <h5 style={{color: "black"}}>Please fill in your details to get us help you!</h5>
          <span style={{
            color: "black",
            fontSize: "0.8rem",
            fontWeight: 500,
          }}>
            <span style={{color: "red"}}>*</span> Required
          </span>
          </div>
          <label style={{color: "black", justifySelf: 'left', fontSize: "0.9rem", fontWeight: '500'}}>Name<span style={{color: "red"}}>*</span></label>
          <input required id='name' type="text" className='input' placeholder="Name" style={{padding: "1rem", paddingTop: "0.8rem", paddingBottom: "0.8rem", width: "100%", borderRadius: "0.3rem", border: "#e4e4e4 0.15em solid", outline: "none", flexGrow: 1}} />
          <div style={{display: "grid", gridTemplateColumns: "1fr 1fr", gap: 20}}>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
              <label style={{color: "black", justifySelf: 'left', fontSize: "0.9rem", fontWeight: '500'}}>Email<span style={{color: "red"}}>*</span></label>
              <input required id="email" type="text" className='input' placeholder="Email" style={{padding: "1rem", paddingTop: "0.8rem", paddingBottom: "0.8rem", borderRadius: "0.3rem", border: "#e4e4e4 0.15em solid", outline: "none", flexGrow: 1}} />
            </div>

            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
              <label style={{color: "black", justifySelf: 'left', fontSize: "0.9rem", fontWeight: '500'}}>Company Name<span style={{color: "red"}}>*</span></label>
              <input required id="company" type="text" className='input' placeholder="Your Company Name" style={{padding: "1rem", paddingTop: "0.8rem", paddingBottom: "0.8rem", borderRadius: "0.3rem", border: "#e4e4e4 0.15em solid", outline: "none", flexGrow: 1}} />
            </div>
          </div>
          <label style={{color: "black", justifySelf: 'left', fontSize: "0.9rem", fontWeight: '500'}}>Phone<span style={{color: "red"}}>*</span></label>
          <input required id='phone' type="text" className='input' placeholder="Phone" style={{padding: "1rem", paddingTop: "0.8rem", paddingBottom: "0.8rem", width: "100%", borderRadius: "0.3rem", border: "#e4e4e4 0.15em solid", outline: "none", flexGrow: 1}} />
          <a href={uriOfSelectedProduct} target="_blank" rel="noreferrer">
          <button
            type='submit'
            className='btn'
            disabled={
              document.getElementById("name")?.value==="" || document.getElementById("email")?.value==="" || document.getElementById("phone")?.value===""
            }
            style={{background: "black", padding: "1rem", paddingTop: '0.8rem', paddingBottom: '0.8rem', color: "white", marginTop: 10}} onClick={()=>{submitForm(); setleadFormFilledFilled(true); setshowLeadGenForm(false)}}>
            Submit
          </button>
          </a>
        </div>
      </div>
        )
        :
        null
      }
        <Header />
        <div className="main" style={{marginLeft: 50, marginRight: 50}}>
            <div className="upper" style={{display: 'flex', justifyContent: 'space-between', marginTop: 70, paddingTop: 50}}>
                <div className="upper__left">
                    <img src={categoryImages[params.category]} className='sep-cat-img' width={700} height={400} style={{objectFit: 'cover'}} alt="category image" />
                </div>
            <div className="upper_right">
                <h1>{params.category}</h1>
                {
                  params.category in brochures ? <a href={brochures[params.category]} target="_blank" rel="noreferrer" className='download-a'>
                  <button className="btn" style={{background: "black", padding: "1rem", marginBottom: '1rem', paddingTop: '0.8rem', paddingBottom: '0.8rem', color: "white", marginTop: 10}}>
                    Download Brochure
                  </button>
                  </a> : null
                }
                      <span className='stars' style={{display: 'flex', justifyContent: 'start'}}>
                         <img src="/playground_assets/star.png" width={20} style={{marginRight: 3}} height={20} alt="" />
                         <img src="/playground_assets/star.png" width={20} style={{marginRight: 3}} height={20} alt="" />
                         <img src="/playground_assets/star.png" width={20} style={{marginRight: 3}} height={20} alt="" />
                         <img src="/playground_assets/star.png" width={20} style={{marginRight: 3}} height={20} alt="" />
                         <img src="/playground_assets/star.png" width={20} height={20} alt="" />
                      </span>
                <p style={{lineHeight: 1.7}}>
                    {categoryDescriptions[params.category]}
                </p>
            </div>
        </div>
        {/* <div className="products-gallery"> */}
        {
        Object.keys(products).map((product, index) => (
          product===params.category && <div className="products-container" id={`product_${index+1}`} style={{marginTop: 0}} key={index}>
            <h1 className="products-text13" style={{marginTop: 30}}>Explore {product}</h1>
            <div className="products-gallery">
              {products[product].map((item, index) => (
                item.url ?
                  // <GalleryCard1
                  //   key={index}
                  //   image_src={item.img}
                  //   title={item.name}
                  //   rootClassName={`rootClassName${index+1}`}
                  //   onClick={()=>{setproduct(item.name); seturiOfSelectedProduct(item.url); leadForm(item.url)}}
                  //   ></GalleryCard1>
                  <div style={{aspectRatio: "1", background: "#fff", position: "relative", boxShadow: "0px 0px 10px rgba(0,0,0,0.4)", gap: "1.5rem", borderRadius: 10, display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center', cursor: 'pointer'}} onClick={()=>{setproduct(item.name); seturiOfSelectedProduct(item.url); leadForm(item.url)}}>
                    <div style={{position: "absolute", left: 0, top: 0 ,height: "100%", width: "78%", zIndex: 10,
                      backgroundColor: (Math.random() > 0.75 ? "#84847c" : Math.random() > 0.5 ? "#957a5d" : Math.random() > 0.25 ? "#9a5d5c" : "#7d6d64"),
                      borderTopLeftRadius: 10, borderBottomLeftRadius: 10, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                      <span className="card-text" style={{fontWeight: "400", fontSize: 40, width: "100%", textAlign: "left", paddingLeft: 28, color: "#fff", lineHeight: 0.87}}>
                        {/* remove 600x1200 or 600x600 from name */}
                        {
                          item.name.split(" ").slice(0, -1).join(" ")
                        }
                      </span>
                    </div>
                    {/* <img src="/playground_assets/pdf.png" alt="" style={{width: '26%', height: '26%', objectFit: 'cover', borderRadius: 10, marginLeft: "auto", marginBottom: "auto", marginTop: 10, cursor: 'pointer'}} onClick={()=>{setproduct(item.name); seturiOfSelectedProduct(item.url); leadForm(item.url)}} /> */}
                    {/* <span style={{fontWeight: "500", fontSize: "1.1rem"}}>{item.name}</span> */}
                    <img src="/playground_assets/downloadIcon.png" alt="" style={{
                      width: '2.3rem',
                      height: '2.3rem',
                      objectFit: 'cover',
                      borderRadius: 10,
                      cursor: 'pointer',
                      position: 'absolute',
                      bottom: 10,
                      right: 10,
                      zIndex: 20
                    }} />
                  </div>
                  :
                  <GalleryCard1
                  key={index}
                  image_src={item.img}
                  title={item.name}
                  rootClassName={`rootClassName${index+1}`}
                  onClick={()=>{setproduct(item.name); leadForm()}}
                  ></GalleryCard1>
              ))}
            </div>
          </div>
        ))
      }
      {
        Object.keys(products2).map((product, index) => (
            product===params.category && <div className="products-container" id={`product_${index+4}`} key={index}>
            <h1 className="products-text13">Our Collection of {product}</h1>
            <div className="products-gallery">
              {products2[product].map((item, index) => (
                <GalleryCard1
                  key={index}
                  image_src={item}
                  title={item.toString().split('/')[4].split(".")[0].split("-").join(" ")}
                  rootClassName={`rootClassName${index+4}`}
                  onClick={()=>{setproduct(item.name); leadForm()}}
                ></GalleryCard1>
              ))}
            </div>
          </div>
        ))
      }
      {
        params.category==="Bathroom & Sanitary Fittings" ? <div className="products-container" style={{minHeight: 'fit-content'}}>
            <span style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                marginTop: 80,
                marginBottom: 60
            }}>
                <h1 style={{
                    fontSize: 40,
                    fontWeight: 500,
                    color: '#000'
                }}>This Category will be Launching Soon!!</h1>
            </span>
        </div> : <div></div>
        
      }
      
            {/* <GalleryCard1 image_src="https://www.bhutrastones.com/wp-content/uploads/2018/12/yellow-valencia-1-1024x683-Copy-300x300.jpg" rootClassName={1} /> */}
        {/* </div> */}
        </div>
        <Footer />
    </div>
  )
}

export default SingleCategory