import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import productsJson from '../products.json'
import { Helmet } from 'react-helmet'
import GalleryCard1 from '../components/gallery-card1'
import './products.css'
import Header from '../components/Header'
import Footer from '../components/Footer'

const Products = (props) => {

  useEffect(()=>{
    window.scrollTo(0, 0)
  })

  const products = JSON.parse(JSON.stringify(productsJson))
  const products2 = {
    "Granite": [
      '/playground_assets/granites/granites2/Amba-White.png',
      '/playground_assets/granites/granites2/Asian-Top.png',
      '/playground_assets/granites/granites2/Astoria.png',
      '/playground_assets/granites/granites2/Cats-Eye.png',
      '/playground_assets/granites/granites2/Colombo-Juparana.png',
      '/playground_assets/granites/granites2/Colonial-White.png',
      '/playground_assets/granites/granites2/Coral-White.png',
      '/playground_assets/granites/granites2/Ghiblee-Gold.png',
      '/playground_assets/granites/granites2/Golden-Beach.png',
      '/playground_assets/granites/granites2/Golden-Oak.png',
      '/playground_assets/granites/granites2/Imperial-Gold.png',
      '/playground_assets/granites/granites2/Ivory-Brown.png',
      '/playground_assets/granites/granites2/Ivory-Chiffon.png',
      '/playground_assets/granites/granites2/Kashmir-Cream.png',
      '/playground_assets/granites/granites2/New-Imperial-Gold.png',
      '/playground_assets/granites/granites2/New-Kashmir-White.png',
      '/playground_assets/granites/granites2/Paradiso-Bash.png',
      '/playground_assets/granites/granites2/Paradiso-Classic.png',
      '/playground_assets/granites/granites2/Pink-Juparana.png',
      '/playground_assets/granites/granites2/Red-Multi-Colour.png',
      '/playground_assets/granites/granites2/River-White.png',
      '/playground_assets/granites/granites2/Rosewood.png',
      '/playground_assets/granites/granites2/Shivakshi-Gold.png',
      '/playground_assets/granites/granites2/Shivakshi-Pink.png',
      '/playground_assets/granites/granites2/Strawberry-Pink.png',
      '/playground_assets/granites/granites2/Thunder-White.png',
      '/playground_assets/granites/granites2/Viscon-White.png',
      '/playground_assets/granites/granites1/Arizona-Gold.png',
      '/playground_assets/granites/granites1/Black-Galaxy-Brush-Hammered.png',
      '/playground_assets/granites/granites1/Black-Galaxy-Honed.png',
      '/playground_assets/granites/granites1/Black-Galaxy-Flamed.png',
      '/playground_assets/granites/granites1/Black-Galaxy-Lapatro.png',
      '/playground_assets/granites/granites1/Black-Galaxy.png',
      '/playground_assets/granites/granites1/Black-Pearl.png',
      '/playground_assets/granites/granites1/Flash-Blue.png',
      '/playground_assets/granites/granites1/G20.png',
      '/playground_assets/granites/granites1/Impala-Black.png',
      '/playground_assets/granites/granites1/Imperial-Brown.png',
      '/playground_assets/granites/granites1/Indian-Black.png',
      '/playground_assets/granites/granites1/Kingfisher-Blue.png',
      '/playground_assets/granites/granites1/Lavender-Blue.png',
      '/playground_assets/granites/granites1/Moon-White.png',
      '/playground_assets/granites/granites1/New-Tropic-Brown.png',
      '/playground_assets/granites/granites1/Prada-Gold.png',
      '/playground_assets/granites/granites1/SK-Blue.png',
      '/playground_assets/granites/granites1/Spice-Black.png',
      '/playground_assets/granites/granites1/Star-Galaxy.png',
      '/playground_assets/granites/granites1/Steel-Grey.png',
      '/playground_assets/granites/granites3/Absolute-Black.png',
      '/playground_assets/granites/granites3/Coffee-Brown.png',
      '/playground_assets/granites/granites3/Khammam-Black.png',
      '/playground_assets/granites/granites3/Pegasus-Brown.png',
      '/playground_assets/granites/granites3/Sapphire-Brown.png',
      '/playground_assets/granites/granites3/Tan-Brown.png',
      '/playground_assets/granites/granites4/Bala-Flower.png',
      '/playground_assets/granites/granites4/Black-Magic.png',
      '/playground_assets/granites/granites4/Chima-Pink.png',
      '/playground_assets/granites/granites4/Crystal-Yellow-1.png',
      '/playground_assets/granites/granites4/Crystal-Yellow-2.png',
      '/playground_assets/granites/granites4/Desert-Brown.png',
      '/playground_assets/granites/granites4/French-Green.png',
      '/playground_assets/granites/granites4/Indian-Green-Marble.png',
      '/playground_assets/granites/granites4/Jhansi-Red.png',
      '/playground_assets/granites/granites4/Koliwada.png',
      '/playground_assets/granites/granites4/New-Imperial-Red.png',
      '/playground_assets/granites/granites4/P-White.png',
      '/playground_assets/granites/granites4/Pink-Panther.png',
      '/playground_assets/granites/granites4/Rajasthan-Black.png',
      '/playground_assets/granites/granites4/Raniwada.png',
      '/playground_assets/granites/granites4/Rosy-Pink.png',
      '/playground_assets/granites/granites4/Royal-Cream.png',
      '/playground_assets/granites/granites4/S-White.png',
      '/playground_assets/granites/granites4/Tiger-Skin.png',
      '/playground_assets/granites/granites4/Tomato-Red.png',
      '/playground_assets/granites/granites4/Z-Brown.png',
    ]
  }
  return (
    <div className="products-container">
      <Helmet>
        <title>Products - Sontuosità Online Store</title>
        <meta property="og:title" content="Products - Sontuosità Online Store" />
      </Helmet>
      <Header />
      {
        Object.keys(products).map((product, index) => (
          <div className="products-container" id={`product_${index+1}`} key={index}>
            <h1 className="products-text13">Our Collection of {product}</h1>
            <div className="products-gallery">
              {products[product].map((item, index) => (
                <GalleryCard1
                  key={index}
                  image_src={item.img}
                  title={item.name}
                  rootClassName={`rootClassName${index+1}`}
                ></GalleryCard1>
              ))}
            </div>
          </div>
        ))
      }
      {
        Object.keys(products2).map((product, index) => (
          <div className="products-container" id={`product_${index+4}`} key={index}>
            <h1 className="products-text13">Our Collection of {product}</h1>
            <div className="products-gallery">
              {products2[product].map((item, index) => (
                <GalleryCard1
                  key={index}
                  image_src={item}
                  title={item.toString().split('/')[4].split(".")[0].split("-").join(" ")}
                  rootClassName={`rootClassName${index+4}`}
                ></GalleryCard1>
              ))}
            </div>
          </div>
        ))
      }
      
      
      <Footer />
    </div>
  )
}

export default Products
