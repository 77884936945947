import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import SectionHeading from '../components/section-heading'
import CategoryCard from '../components/category-card'
import ItemCard from '../components/item-card'
import './home.css'
import Header from '../components/Header'
import Footer from '../components/Footer'

const Home = (props) => {

  return (
    <div className="home-container">
      <Helmet>
        <title>Sontuosità Online Store</title>
        <meta property="og:title" content="Sontuosità Online Store" />
      </Helmet>
      <Header />
      <div className="home-main">
        <div className="home-hero section-container">
          <div className="home-max-width max-width-container">
            <div className="home-hero1">
              <div className="home-container03">
                <div className="home-info">
                  <img
                    alt="Rectangle43271305"
                    src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMicgaGVpZ2h0PSc1Micgdmlld0JveD0nMCAwIDIgNTInIGZpbGw9J25vbmUnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+CjxyZWN0IHdpZHRoPScyJyBoZWlnaHQ9JzUyJyBmaWxsPSdibGFjaycgZmlsbC1vcGFjaXR5PScwLjUnLz4KPC9zdmc+Cg=="
                    className="home-image1"
                  />
                  <span className="home-text13">
                    <span>TILES</span>
                    <br></br>
                    <span>trends - 2023</span>
                  </span>
                </div>
                <h1 className="home-text17 Heading-1">
                  CRAFTING BEAUTIFUL SPACES, INSIDE &amp; OUT
                </h1>
                <div className="home-container04">
                  <span className="home-text18">AFTERMATH OF</span>
                  <span className="home-text19">LUXURY</span>
                </div>
                <div className="home-btn-group">
                  <Link to="/products">
                    <button className="button">Explore the collection</button>
                  </Link>
                </div>
              </div>
              <img
                alt="image23271449"
                src="/playground_assets/tiles.png"
                className="home-image2"
              />
            </div>
          </div>
        </div>
        <div className="section-container column">
          <span style={{
            color: '#000000',
            fontSize: 38,
            fontWeight: 500,
            letterSpacing: 1.5,
            textAlign: 'center',
          }}>WELCOME TO SONTUOSITÀ</span>
        <div className="home-container07">
              <span className="home-text26">
                <span>
                Sontuosità is your one-stop solution for premium quality interior materials that are designed to elevate the aesthetics of any space. We specialize in manufacturing, wholesaling, and exporting a wide range of materials, such as Concrete Tiles, Marbles, Granite Countertops, and much more. Our commitment to quality is evident in every material we produce, and our customers know that when they choose Sontuosità, they are getting the best of the best.
                </span>
                <br></br>
                <br></br>
                <span>
                We manufacture our products using the finest materials and are available in a wide range of colors, sizes, and designs to meet the diverse needs of our customers. Our commitment to quality and customer satisfaction is unmatched, making us the go-to choice for all your interior material needs. So, if you're looking for premium quality materials that can enhance the beauty of your projects, choose Sontuosità.
                </span>
                <br></br>
                <br></br>
                <span>
                At Sontuosità, we take pride in working with reliable vendors who are dedicated to designing our products in compliance with the highest international quality standards. Our products are stored in a well-organized warehouse to ensure prompt delivery of consignments to our clients. Whether you're an architect looking for a wide range of options to bring your design ideas to life or a project manager looking for high-quality materials for your upcoming project, Sontuosità has got you covered.
                </span>
                <br></br>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
              <img
                alt="M3271427"
                src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMTk5JyBoZWlnaHQ9JzIwMCcgdmlld0JveD0nMCAwIDE5OSAyMDAnIGZpbGw9J25vbmUnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+CjxwYXRoIGQ9J00zNy4zMDI2IDcxLjI5MjVMOTkuMzgyNyAxODIuMzEzTDE2MS40NjMgNzEuMjkyNUwxNzUuNjIyIDIwMEgxOTguNzY1TDE3NS42MjIgMEw5OS4zODI3IDEzNy45NTlMMjMuMTQzOSAwTDAgMjAwSDIzLjE0MzlMMzcuMzAyNiA3MS4yOTI1WicgZmlsbD0nYmxhY2snIGZpbGwtb3BhY2l0eT0nMC4wNicvPgo8L3N2Zz4K"
                className="home-svg"
              />
              <Link to="/about">
                <button className="button mera-btn" id="products">Read more</button>
              </Link>
            </div>
          
          <div className="home-banner">
            <div className="home-container05">
              <h3 className="home-text20">
                <span className="Heading-3" style={{marginLeft: 9}}>SONTUOSITÀ</span>
              </h3>
              <span className="home-text23">
                <span></span>
                <span style={{letterSpacing: 3.5}}>Aftermath Of Luxury</span>
              </span>
            </div>
          </div>
          <div className="home-container06 max-width-container">
          <div className="max-width-container category-container001">
            <SectionHeading
              heading="SHOP BY CATEGORIES"
              subtitle="Start shopping based on the categories you are interested in"
            ></SectionHeading>
            <div className="home-cards-container">
              <Link to="/products/Concrete%20Tiles">
                <CategoryCard
                  name="Concrete Tiles"
                  category_img="https://thenuancestudio.com/assets/img/fuji.jpg"
                ></CategoryCard>
              </Link>
              <Link to="/products/Imported%20Marbles">
              <CategoryCard
                name="Imported Marble"
                category_img="/playground_assets/imported-1500w.jpg"
              ></CategoryCard>
              </Link>
              <Link to="/products/Indian%20Marbles">
              <CategoryCard
                name="Indian Marbles"
                category_img="/playground_assets/indian-1500w.jpeg"
                ></CategoryCard>
              </Link>
              <Link to="/products/Granite">
              <CategoryCard
                name="Granite"
                category_img="/playground_assets/granite2-1500w.webp"
              ></CategoryCard>
              </Link>
              <Link to="/products/Vitrified%20Tiles">
              <CategoryCard
                name="Vitrified Tiles"
                category_img="/playground_assets/vetrified.webp"
              ></CategoryCard>
              </Link>
              <Link to="/products/Bathroom & Sanitary%20Fittings">
              <CategoryCard
                name="Bathroom & Sanitary Fittings"
                category_img="/playground_assets/bathroom.png"
              ></CategoryCard>
              </Link>
              
            </div>
          </div>
          </div>
        </div>
        <div className="section-container">
          <div className="max-width-container" id='trending'>
            <SectionHeading
              heading="OUR TRENDING PRODUCTS"
              subtitle="Explore our monthly most trending Products, new items and the best Sontuosità offers you can buy"
            ></SectionHeading>
            <div className="home-gallery">
              <div className="home-left1">
                <ItemCard
                  name="Designer Vitrified Tiles"
                  value=" "
                  currency=" "
                  image_src="/playground_assets/marblewood-1500w.webp"
                  rootClassName="rootClassName4"
                ></ItemCard>
              </div>
              <div className="home-right1">
                <div className="home-top">
                  <div className="home-left2">
                    <ItemCard
                      name="Fuji Wall Tiles"
                      image_src="https://thenuancestudio.com/assets/img/fuji.jpg"
                      rootClassName="rootClassName2"
                    ></ItemCard>
                  </div>
                  <div className="home-right2">
                    <ItemCard
                      name="Granite Countertops"
                      value="299"
                      image_src="/playground_assets/indian-kitchen.jpg"
                      rootClassName="rootClassName3"
                    ></ItemCard>
                  </div>
                </div>
                <div className="home-bottom">
                  <div className="home-left3">
                    <ItemCard
                      name="Imported Marbles Interior"
                      value="149"
                      image_src="https://www.bhutrastones.com/wp-content/uploads/2021/12/Armani.jpg"
                      rootClassName="item-card-root-class-name1"
                    ></ItemCard>
                  </div>
                  <div className="home-right3">
                    <ItemCard
                      name="Litheoz Basin"
                      value="49"
                      image_src="https://thenuancestudio.com/assets/img/fiore-bg.jpg"
                      rootClassName="item-card-root-class-name6"
                    ></ItemCard>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="home-full-width-banner section-container">
          <div className="home-left4">
            <div className="home-content">
              <span className="home-text33">LOOKBOOKS</span>
              <span className="home-text34">
                Checkout our Elegant Projects<br/>
                Carefully curated Interior with Tiles &amp; marbles, well matched in style and
                looks!
              </span>
            </div>
            <div className="home-btn button">
              <Link to="/applications" className="home-text35">
                <span className="home-text35">Explore our Projects</span>
              </Link>
            </div>
          </div>
          <img
            alt="Rectangle13271410"
            src="/playground_assets/granite2-1500w.jpeg"
            className="home-image3"
          />
        </div>
      </div>
      
      <Footer />
    </div>
  )
}

export default Home
