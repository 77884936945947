import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './about.css'
import Header from '../components/Header'
import Footer from '../components/Footer'

const About = (props) => {

  useEffect(()=>{
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="about-container">
      <Helmet>
        <title>About - Sontuosità Online Store</title>
        <meta property="og:title" content="About - Sontuosità Online Store" />
      </Helmet>
      {/* <div className="about-navbar" id="about-header">
        <header data-role="Header" className="about-header max-width-container">
          <div className="about-navbar1">
            <div className="about-container01">
            <Link to="/">
              <img
                alt="search3271286"
                src="/playground_assets/home.svg"
                className="home-image"
                style={{
                  marginLeft: '20px',
                  marginRight: '-20px'
                }}
              />
              </Link>
            </div>
            <div className="about-middle">
              <div className="about-left">
                <Link to="/products" className="about-text navbar-link">
                  PRODUCTS
                </Link>
                <Link to="/applications" className="about-text01 navbar-link">
                  APPLICATIONS
                </Link>
                <Link to="/testimonials" className="about-text02 navbar-link">
                  TESTIMONIALS
                </Link>
              </div>
              <Link to="/" className="about-logo-center navbar-logo-title">
                <span>SONTUOSITÀ</span>
                <br></br>
              </Link>
              <div className="about-right">
                <Link to="/about" className="about-text05 navbar-link">
                  ABOUT
                </Link>
                <a href="#contact-details" className="about-text06 navbar-link">
                <span className="navbar-link">CONTACT</span>
                </a>
              </div>
            </div>
            <div className="about-icons">
              <a href="tel:+918373958829" className="about-link">
                <svg viewBox="0 0 1024 1024" className="about-icon">
                  <path d="M854 656q18 0 30 12t12 30v148q0 50-42 50-298 0-512-214t-214-512q0-42 50-42h148q18 0 30 12t12 30q0 78 24 150 8 26-10 44l-82 72q92 192 294 290l66-84q12-12 30-12 10 0 14 2 72 24 150 24z"></path>
                </svg>
              </a>
              <a
                href="mailto:vishalvishwajeet422@gmail.com?subject="
                className="about-link1"
              >
                <svg viewBox="0 0 1024 1024" className="about-icon02">
                  <path d="M854 342v-86l-342 214-342-214v86l342 212zM854 170q34 0 59 26t25 60v512q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h684z"></path>
                </svg>
              </a>
              <svg
                viewBox="0 0 877.7142857142857 1024"
                className="about-icon04"
              >
                <path d="M585.143 786.286v-91.429c0-10.286-8-18.286-18.286-18.286h-54.857v-292.571c0-10.286-8-18.286-18.286-18.286h-182.857c-10.286 0-18.286 8-18.286 18.286v91.429c0 10.286 8 18.286 18.286 18.286h54.857v182.857h-54.857c-10.286 0-18.286 8-18.286 18.286v91.429c0 10.286 8 18.286 18.286 18.286h256c10.286 0 18.286-8 18.286-18.286zM512 274.286v-91.429c0-10.286-8-18.286-18.286-18.286h-109.714c-10.286 0-18.286 8-18.286 18.286v91.429c0 10.286 8 18.286 18.286 18.286h109.714c10.286 0 18.286-8 18.286-18.286zM877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
              </svg>
            </div>
          </div>
          <div data-role="BurgerMenu" className="about-burger-menu">
            <svg viewBox="0 0 1024 1024" className="about-icon06">
              <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
            </svg>
          </div>
          <div data-role="MobileMenu" className="about-mobile-menu">
            <div className="about-nav">
              <div className="about-container02">
                <span className="about-logo-center1">SONTUOSITÀ</span>
                <div
                  data-role="CloseMobileMenu"
                  className="about-close-mobile-menu"
                >
                  <svg viewBox="0 0 1024 1024" className="about-icon08">
                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
              <div className="about-middle1">
              <Link to="/products" className="home-text navbar-link">
                  PRODUCTS
                </Link>
                <Link to="/applications" className="home-text01 navbar-link">
                  APPLICATIONS
                </Link>
                <Link to="/testimonials" className="home-text02 navbar-link">
                  TESTIMONIALS
                </Link>
                <Link to="/about" className="home-text05 navbar-link">
                  ABOUT
                </Link>
                <a href="#contact-details" className="home-text06 navbar-link">
                  CONTACT
                </a>
              </div>
            </div>
            <div>
              <svg
                viewBox="0 0 950.8571428571428 1024"
                className="about-icon10"
              >
                <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
              </svg>
              <svg
                viewBox="0 0 877.7142857142857 1024"
                className="about-icon12"
              >
                <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
              </svg>
              <svg
                viewBox="0 0 602.2582857142856 1024"
                className="about-icon14"
              >
                <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
              </svg>
            </div>
          </div>
        </header>
      </div> */}
      <Header />
      <div className="about-container03">
        <div className="about-banner">
          <h1 className="about-text13">Our Mission</h1>
          <span className="about-text14">
            <span>
            Our mission at Sontuosità is to provide our customers with the highest quality interior materials to enhance the beauty of their projects. We strive to be a one-stop solution for all their interior material needs, offering a wide range of options to choose from. We aim to maintain our reputation for excellence by using only the best quality materials and providing exceptional customer service.
            </span>
            <br></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </span>
        </div>
        <div className="about-banner1">
          <h1 className="about-text19">Our Vision</h1>
          <span className="about-text20">
            <span>
            Our vision at Sontuosità is to be a leading manufacturer, wholesaler, and exporter of premium quality interior materials. We aspire to be the go-to choice for architects, project managers, and individuals looking for top-quality materials to give their projects the best possible look. We will continue to expand our product line and focus on innovation to meet the ever-changing needs of the industry.
            </span>
            <br></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </span>
        </div>
      </div>
      <div className="about-banner2" id="about_2">
        <div className="about-container04">
          <h1 className="about-text25">Why Us?</h1>
          <span className="about-text26">
            {/* <span>
              We are a well-known wholesaler and exporter of natural stones,
              offering a diverse range of products such as Marble, Granite,
              Concrete Tiles and Bathroom Fittings. Our products are carefully
              sourced from reliable vendors who adhere to international quality
              standards. We maintain a well-organized warehouse where our
              products are stored, ensuring that we can deliver orders to our
              clients quickly and efficiently. Our range of natural stones comes
              in various sizes and specifications to meet the diverse needs of
              the market.
            </span> */}
            {/* <br />
            <br /> */}
            <span>
            As a wholesale provider of interior materials, Sontuosità is committed to offering competitive pricing to our customers. We work closely with our partners to ensure that our products are priced affordably while still maintaining the high quality that our customers have come to expect from us. We offer flexible ordering options to ensure that our customers can get the materials they need, when they need them.
            </span>
            <br /><br />
            <span>
            Sontuosità's commitment to quality extends beyond our borders, as we export our interior materials to countries around the world. We work closely with our international partners to ensure that our products meet all of the necessary standards and regulations for each country. Our international customers know that they can count on us to deliver high-quality materials on time, every time.
            </span>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </span>
          <div className="about-container05"></div>
        </div>
        <img
          alt="image"
          src="https://5.imimg.com/data5/SELLER/Default/2020/10/WJ/MZ/MW/20571801/glossy-brown-ceramic-vitrified-floor-tile-1000x1000.jpg"
          className="about-image1"
        />
      </div>
      <div className="about-banner3" id="about_3">
        <div className="about-container06">
          <h1 className="about-text30">Our Quality Policy</h1>
          <span className="about-text31">
            <span>
              Our top priority is complete customer satisfaction, which is why
              we continually strive to adapt our systems and processes to meet
              the ever-changing needs of our clients. Our unwavering commitment
              to detail ensures that we meet or exceed our customers’
              expectations. Our policy on quality emphasizes flawless production
              processes and timely deliveries.
            </span>
            <br></br>
            <br></br>
            <span>
              We conduct regular briefings with our customers to inform them of
              our product ranges, and we continually seek to improve all aspects
              of our business. Ongoing training for our staff is a fundamental
              philosophy of our company. We work with the best suppliers to
              ensure that we achieve the best quality at the most competitive
              prices for our raw materials. Our brand represents our commitment
              to quality and sets the standard for our future.
            </span>
            <br></br>
            <br></br>
            <span>
              Our goal is to offer an extensive range of high-quality products
              in a wide variety of colors, with sharp finishes and precise cuts
              that set us apart from our competitors. Our skilled purchasing
              team has a deep understanding of the needs and preferences of
              architects and interior designers. We employ quality control
              engineers to maintain strict vigilance over our products’ quality.
              We provide inspection reports and test certificates for all of our
              products to guarantee their quality and avoid any defects.
            </span>
          </span>
        </div>
        <div className="about-container07"></div>
      </div>
      
      <Footer />
    </div>
  )
}

export default About
