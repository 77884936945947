import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <div className="home-footer" id='contact-details'>
        <div className="max-width-container">
          <footer className="home-footer1">
            <div className="home-container08">
              <h3 className="home-text36 Heading-3">SONTUOSITÀ</h3>
              <span className="home-text37">
              <span>102 Road No. 4, Defence Layout,</span>
                <br></br>
                <span>Vidyaranyapura, Bengaluru 560097, India</span>
              </span>
              <a href='tel:918495042742' className="testimonials-text52">+91 8495042742</a>
              {/* <span className="home-text41">+91 9999999999</span> */}
              <span className="home-text42">hello@sontuosita.com</span>
            </div>
            <div className="home-links-container">
              <div className="home-container09">
                <span className="home-text43">Categories</span>
              <Link to="/products/Imported%20Marbles" onClick={()=>{window.scrollTo(0, 0)}} className='home-text44'>
                <span className="home-text44">Imported Marbles</span>
                </Link>
                <Link to="/products/Indian%20Marbles" onClick={()=>{window.scrollTo(0, 0)}} className="home-text45">
                  <span className="home-text45">Indian Marbles</span>
                </Link>
                <Link to="/products/Concrete%20Tiles" onClick={()=>{window.scrollTo(0, 0)}} className="home-text46">
                  <span className="home-text46">Concrete Tiles</span>
                </Link>
                <Link to="/products/Granite" className="home-text47" onClick={()=>{window.scrollTo(0, 0)}}>
                <span className="home-text47">Granite</span>
                </Link>
                <Link to="/products/Vitrified%20Tiles" className="home-text48" onClick={()=>{window.scrollTo(0, 0)}}>
                  <span>Vitrified Tiles</span>
                  <br></br>
                </Link>
                <Link to="/products/Bathroom & Sanitary%20Fittings" className='home-text51' onClick={()=>{window.scrollTo(0, 0)}}>
                  <span className="home-text51">Bathroom Fittings & Sanitary</span>
                </Link>
              </div>
              <div className="home-container10">
                <span className="home-text52">Company</span>
                <Link to="/about" className="home-text53">
                  <span className="home-text53">About Us</span>
                </Link>
                <a href="/about#about_3" className='home-text54'>
                  <span className="home-text54">Quality Policy</span>
                </a>
                <Link to="/testimonials" className="home-text55">
                  <span className="home-text55">Happy Clients</span>
                </Link>
                <a href="/about#about_2" className='home-text56'>
                  <span className="home-text56">Why Us</span>
                </a>
              </div>
              <div className="home-container11">
                <span className="home-text57">Resources</span>
                <Link to="/contact" className="home-text58">
                  <span className="home-text58">Contact us</span>
                </Link>
                <a href="https://externs.tech/" target='_blank' style={{
                  color: '#888',
                }}>
                  <span className="home-text59">Contact Developers</span>
                </a>
              </div>
            </div>
          </footer>
          <a href="https://externs.tech/" target='_blank' style={{
            marginBottom: '20px',
            marginTop: '20px',
            color: '#333',
            textAlign: 'center',
          }}>
            Website Developed by Externs Technolgies
          </a>
        </div>
      </div>
  )
}

export default Footer