import React from 'react'

import PropTypes from 'prop-types'

import './gallery-card1.css'

const GalleryCard1 = (props) => {
  // alert(props.image_src.split("/"))
  return (
    <div className={`gallery-card1-gallery-card ${props.rootClassName} `} onClick={props.onClick}>
      <img
        alt={props.image_alt}
        src={props.image_src}
        className="gallery-card1-image"
      />
      <h2 className="gallery-card1-text" style={{fontWeight: '500'}}>{props.title}</h2>
      {/* <span className="gallery-card1-text1">{props.subtitle}</span> */}
    </div>
  )
}

GalleryCard1.defaultProps = {
  title: 'Project Title',
  image_alt: 'image',
  subtitle: 'Lorem ipsum dolor sit amet',
  image_src:
    'https://images.unsplash.com/photo-1418065460487-3e41a6c84dc5?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDEyfHxmb3Jlc3R8ZW58MHx8fHwxNjI2MjUxMjg4&ixlib=rb-1.2.1&h=1200',
  rootClassName: '',
}

GalleryCard1.propTypes = {
  title: PropTypes.string,
  image_alt: PropTypes.string,
  subtitle: PropTypes.string,
  image_src: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default GalleryCard1
