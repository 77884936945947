import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import TestimonialCard3 from '../components/testimonial-card3'
import './testimonials.css'
import Footer from '../components/Footer'
import Header from '../components/Header'

const Testimonials = (props) => {

  useEffect(()=>{
    window.scrollTo(0,0)
  })

  return (
    <div className="testimonials-container">
      <Helmet>
        <title>Testimonials - Sontuosità Online Store</title>
        <meta
          property="og:title"
          content="Testimonials - Sontuosità Online Store"
        />
      </Helmet>
      <Header />
      <div className="testimonials-testimonial">
        <h1 className="testimonials-text13">
          <span>Our clients speak</span>
        </h1>
        <span className="testimonials-text15">
          <span>Discover What Our Customers Have to Say About Our</span>
          <br></br>
          <span>Tiles and Marbles!</span>
        </span>
        <div className="testimonials-container3">
          <TestimonialCard3
            quote="
            I recently renovated my kitchen and bathroom with interior products from Sontuosità, and I am extremely satisfied with the outcome. The quality of their products is exceptional, and they offer a wide range of designs and colors to suit any interior style. The customer service experience was outstanding, as the staff went above and beyond to assist me in finding the perfect elements for my home renovation project."
            picture_src="/playground_assets/indian2.jpg"
            name="Rahul Sahni"
            designation="Civil Engineer, Bangalore"
            rootClassName="rootClassName"
          ></TestimonialCard3>
          <TestimonialCard3
            quote="I had the pleasure of working with Sontuosità for my interior design project, and I couldn't be more thrilled with the results. Their attention to detail and expertise in creating a cohesive and stylish interior truly transformed my space. From furniture selection to color schemes and accessories, every element was thoughtfully curated. The team at Sontuosità was professional, responsive, and dedicated to bringing my vision to life."
            picture_src="/playground_assets/indian1.jpeg"
            name="Deepak Sharma"
            designation="Senior Architect, Mumbai"
            rootClassName="rootClassName"
          ></TestimonialCard3>
          <TestimonialCard3
            quote="Sontuosità has been my go-to source for all my interior needs. From furniture to decorative items, they offer a remarkable selection of high-quality products. Their team has an impeccable eye for design and always provides personalized recommendations to suit my style and preferences. The level of customer service I have received is exceptional, making every interaction a delightful experience."
            picture_src="/playground_assets/indian2.jpeg"
            name="Atharva Kulkarni"
            designation="Interior Designer, Pune"
            rootClassName="rootClassName"
          ></TestimonialCard3>
        </div>
      </div>
      <div className="testimonials-stats">
        <div className="testimonials-stat">
          <h1 className="testimonials-text19">
            <span>100</span>
            <span>+</span>
          </h1>
          <span className="testimonials-text22">Happy clients</span>
          <span className="testimonials-text23">
            <span>Our Grateful Clients across the</span>
            <br></br>
            <span>Country!</span>
            <br></br>
          </span>
        </div>
        <div className="testimonials-stat1">
          <h1 className="testimonials-text28">350+</h1>
          <span className="testimonials-text29">Deliveries completed</span>
          <span className="testimonials-text30">
            <span>Our more than 350 On-Time Deliveries</span>
            <br></br>
            <span>of tiles &amp; marbles!</span>
          </span>
        </div>
        <div className="testimonials-stat2">
          <h1 className="testimonials-text34">
            <span>20</span>
            <span>+</span>
          </h1>
          <span className="testimonials-text37">Service Workers</span>
          <span className="testimonials-text38">
            <span>All of our service workers dedicated to</span>
            <br></br>
            <span>your Project!</span>
            <br></br>
          </span>
        </div>
        <div className="testimonials-stat3">
          <h1 className="testimonials-text43">
            <span>24/7</span>
          </h1>
          <span className="testimonials-text45">Support</span>
          <span className="testimonials-text46">
            We&apos;ve got your back 24/7 for any assistance!
          </span>
        </div>
      </div>
      

      <Footer />
    </div>
  )
}

export default Testimonials
